import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import classes from "../css/settings.module.css";
import EditLogo from "../components/svg-components/EditLogo";
import CancelLogo from "../components/svg-components/CancelLogo";
import { useApi } from "../contexts/ApiProvider";
import { useUser } from "../contexts/UserProvider";
import Body from "../components/Body";
import formattedDate from "../utils/date_utils";
import PrimaryButton from "../components/PrimaryButton";
import SettingsSidebar from "../components/SettingsSidebar";
import ChangePasswordPage from "./ChangePasswordPage";
import LoadingIcon from "../components/LoadingIcon";

export default function SettingsPage({ type }) {
  const api = useApi();
  const { user } = useUser();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [editEmail, setEditEmail] = useState(false);
  const [editFirstName, setEditFirstName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [isHovered, setIsHovered] = useState({ location: false });

  const onSubmit = async (ev) => {
    ev.preventDefault();
  };

  const saveChange = async (e) => {};

  const handleMouseEnter = (id) => {
    setIsHovered((prevState) => ({ ...prevState, [id]: true }));
  };

  const handleMouseLeave = (id) => {
    setIsHovered((prevState) => ({ ...prevState, [id]: false }));
  };

  return (
    <Body>
      {user === undefined ? (
        <LoadingIcon />
      ) : (
        <>
          {user === null ? (
            <p>User not found</p>
          ) : (
            <div className={classes["settings-page-container"]}>
              {!isMobile && <SettingsSidebar userid={user.id} type={type} />}
              <div className={classes["settings-page-content"]}>
                <div className={classes["settings-header"]}>
                  <h2>Settings</h2>
                </div>
                {type === "settings" && (
                  <div>
                    <h3>
                      {user.first_name} {user.last_name}
                    </h3>
                    <p className={classes["joined-date"]}>
                      Joined {formattedDate(user.created_at)}
                    </p>
                  </div>
                )}
                {type === "settings" ? (
                  <div className={classes["settings-content"]}>
                    <form
                      className={classes["form"]}
                      key="firstName"
                      onSubmit={onSubmit}
                      onMouseEnter={() => {
                        handleMouseEnter("firstName");
                      }}
                      onMouseLeave={() => {
                        handleMouseLeave("firstName");
                      }}
                    >
                      <div className={classes["input-group"]}>
                        <label htmlFor="firstName">First</label>
                        {editFirstName ? (
                          <input
                            type="firstName"
                            name="firstName"
                            id="firstName"
                            value={firstName}
                            placeholder={user.first_name}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                          />
                        ) : (
                          <span>{user.first_name}</span>
                        )}
                      </div>

                      {editFirstName ? (
                        <div className={classes["btn-container"]}>
                          <PrimaryButton
                            buttonText="Save"
                            onClick={saveChange}
                          />
                          <button
                            onClick={() => {
                              setEditFirstName(false);
                            }}
                            className={classes["logo-btn"]}
                          >
                            <CancelLogo />
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            setEditFirstName(true);
                          }}
                          className={classes["logo-btn"]}
                        >
                          {isHovered["firstName"] && <EditLogo />}
                        </button>
                      )}
                    </form>
                    <form
                      className={classes["form"]}
                      key="lastName"
                      onSubmit={onSubmit}
                      onMouseEnter={() => {
                        handleMouseEnter("lastName");
                      }}
                      onMouseLeave={() => {
                        handleMouseLeave("lastName");
                      }}
                    >
                      <div className={classes["input-group"]}>
                        <label htmlFor="lastName">Last</label>
                        {editLastName ? (
                          <input
                            type="lastName"
                            name="lastName"
                            id="lastName"
                            value={lastName}
                            placeholder={user.last_name}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        ) : (
                          <span>{user.last_name}</span>
                        )}
                      </div>

                      {editLastName ? (
                        <div className={classes["btn-container"]}>
                          <PrimaryButton
                            buttonText="Save"
                            onClick={saveChange}
                          />
                          <button
                            onClick={() => {
                              setEditLastName(false);
                            }}
                            className={classes["logo-btn"]}
                          >
                            <CancelLogo />
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            setEditLastName(true);
                          }}
                          className={classes["logo-btn"]}
                        >
                          {isHovered["lastName"] && <EditLogo />}
                        </button>
                      )}
                    </form>
                    <form
                      className={classes["form"]}
                      key="email"
                      onSubmit={onSubmit}
                      onMouseEnter={() => {
                        handleMouseEnter("email");
                      }}
                      onMouseLeave={() => {
                        handleMouseLeave("email");
                      }}
                    >
                      <div className={classes["input-group"]}>
                        <label htmlFor="email">Email</label>
                        {editEmail ? (
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            placeholder={user.email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        ) : (
                          <span>{user.email}</span>
                        )}
                      </div>

                      {editEmail ? (
                        <div className={classes["btn-container"]}>
                          <PrimaryButton
                            buttonText="Save"
                            onClick={saveChange}
                          />
                          <button
                            onClick={() => {
                              setEditEmail(false);
                            }}
                            className={classes["logo-btn"]}
                          >
                            <CancelLogo />
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            setEditEmail(true);
                          }}
                          className={classes["logo-btn"]}
                        >
                          {isHovered["email"] && <EditLogo />}
                        </button>
                      )}
                    </form>
                  </div>
                ) : type === "password" ? (
                  <ChangePasswordPage />
                ) : null}
              </div>
            </div>
          )}
        </>
      )}
    </Body>
  );
}
