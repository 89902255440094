import React from "react";

import classes from "../css/buttons.module.css";

const DeleteButton = ({ buttonText, onClick }) => {
  return (
    <button className={classes["delete-btn"]} onClick={onClick}>
      {buttonText ? buttonText : "Delete"}
    </button>
  );
};

export default DeleteButton;
