import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import classes from "../css/workout-plans.module.css";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import { useUser } from "../contexts/UserProvider";
import Body from "../components/Body";
import PlanDetails from "../components/PlanDetails";
import PrimaryButton from "../components/PrimaryButton";
import formattedDate from "../utils/date_utils";
import Modal from "../components/Modal";
import DeleteButton from "../components/DeleteButton";
import DotButton from "../components/DotButton";

export default function PlanPage() {
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();
  const { user } = useUser();
  const { userid, planid } = useParams();
  const [exercisePlans, setExercisePlans] = useState();
  const [planTitle, setPlanTitle] = useState("");
  const [workoutPlan, setWorkoutPlan] = useState();
  const [showMenuOptions, setShowMenuOptions] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isMyPlan, setIsMyPlan] = useState();
  const [displayedUser, setDisplayedUser] = useState();

  useEffect(() => {
    setIsMyPlan(user.id === Number(userid));
  }, [user]);

  useEffect(() => {
    (async () => {
      const response = await api.get("/user/" + userid);
      if (response.ok) {
        setDisplayedUser(response.body);
      } else {
        setDisplayedUser(null);
      }
    })();
  }, [api, userid]);

  const updateExercisePlanParams = (plans) => {
    const updatedExercisePlans = plans.map((exercisePlan) => {
      const { workout_plan_id, exercise, ...plan } = exercisePlan;
      plan.exercise_id = exercise.id;
      plan.exercise_name = exercise.name;
      return plan;
    });
    setExercisePlans(updatedExercisePlans);
  };

  useEffect(() => {
    (async () => {
      const response = await api.get(`/workoutplan/${planid}`);
      if (response.ok) {
        const exercisePlans = response.body.exercise_plans;
        setExercisePlans(exercisePlans);
        setWorkoutPlan(response.body);
        setPlanTitle(response.body.title);
        updateExercisePlanParams(exercisePlans);
      } else {
        setExercisePlans(null);
        setWorkoutPlan(null);
      }
    })();
  }, [api, planid]);

  const saveWorkoutPlan = async () => {
    const response = await api.put(`/workoutplan/${planid}`, {
      title: workoutPlan.title,
      exercise_plans: exercisePlans,
    });
    if (response.ok) {
      flash("Workout plan successfully updated", "success");
      navigate(`/plan/${planid}/edit`);
    } else {
      flash("Error updating workout plan", "danger");
    }
  };

  const deleteWorkoutPlan = async () => {
    setModalIsOpen(false);
    const response = await api.delete(`/workoutplan/${planid}`, {
      title: workoutPlan.title,
      exercise_plans: exercisePlans,
    });
    if (response.ok) {
      flash("Workout plan deleted", "success");
      navigate(`/plans`);
    } else {
      flash("Error deleting workout plan", "danger");
    }
  };

  const handleEditClick = () => {
    navigate(`/plan/${planid}/edit`);
  };

  const handleMenuClick = () => {
    setShowMenuOptions(!showMenuOptions);
  };

  const closeDeleteModal = () => {
    setModalIsOpen(false);
    setShowMenuOptions(false);
  };

  const handleAddToMyPlansClick = async () => {
    console.log("add..");
    const updatedExercisePlans = exercisePlans.map((exercisePlan) => {
      const { exercise_name, id, ...plan } = exercisePlan;
      return plan;
    });
    const payload = {
      title: planTitle,
      exercise_plans: updatedExercisePlans,
    };
    console.log(payload);
    const response = await api.post("/workoutplan", payload);
    if (response.ok) {
      flash("Added to my plans", "success");
      setTimeout(() => {
        navigate(`/user/${user.id}/plans`);
      }, 500);
    } else {
      flash("Error adding to my plans. Try again later!", "danger");
    }
  };

  return (
    <Body>
      <div className={classes["plan-container"]}>
        <div className={classes["plan-header"]}>
          <div className={classes["plan-header-left"]}>
            <div>
              <h3>{planTitle}</h3>
              {workoutPlan ? (
                <span className={classes["created-date"]}>
                  Created {formattedDate(workoutPlan.created_at)}
                </span>
              ) : null}
              {displayedUser ? (
                <p className={classes["displayed-user"]}>
                  Author: {displayedUser.first_name} {displayedUser.last_name}
                </p>
              ) : null}
            </div>
            {isMyPlan ? (
              <PrimaryButton onClick={handleEditClick} buttonText="Edit" />
            ) : (
              <PrimaryButton
                onClick={handleAddToMyPlansClick}
                buttonText="+ Add to My Plans"
              />
            )}
          </div>
          {isMyPlan && (
            <div className={classes["plan-header-menu"]}>
              <DotButton onClick={handleMenuClick} />

              {showMenuOptions ? (
                <div>
                  <DeleteButton
                    buttonText="Delete Plan"
                    onClick={() => setModalIsOpen(true)}
                  />
                  <Modal
                    open={modalIsOpen}
                    onClose={closeDeleteModal}
                    onConfirm={deleteWorkoutPlan}
                    msg="Are you sure you want to delete this plan?"
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
        <PlanDetails
          exercisePlans={exercisePlans}
          setExercisePlans={setExercisePlans}
          saveWorkout={saveWorkoutPlan}
          isNewPlan={false}
          readOnlyMode={true}
          planTitle={planTitle}
          setPlanTitle={setPlanTitle}
        />
      </div>
    </Body>
  );
}
