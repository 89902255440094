import { useState, useEffect } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import classes from "../css/plans.module.css";
import { useApi } from "../contexts/ApiProvider";
import LoadingIcon from "./LoadingIcon";

export default function Plans() {
  const api = useApi();
  const navigate = useNavigate();
  const [plans, setPlans] = useState();
  const { userid } = useParams();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/user/${userid}/workoutplan`);
      if (response.ok) {
        setPlans(response.body);
      } else {
        setPlans(null);
      }
    })();
  }, [api]);

  const handlePlanClick = (e) => {
    const planId = Number(e.target.id);
    navigate(`/user/${userid}/plan/${planId}`);
  };

  return (
    <div className={classes["plans-container"]}>
      {plans === undefined ? (
        <LoadingIcon />
      ) : (
        <>
          {plans === null ? (
            <p>Could not retrieve workout plans</p>
          ) : (
            <div className={classes["plan-container"]}>
              <div className={classes["add-plan-btn-container"]}>
                <button
                  className={classes["add-plan-button"]}
                  onClick={() => {
                    navigate("/plans/new");
                  }}
                >
                  + Add a New Plan
                </button>
              </div>
              {plans.length === 0 ? (
                <>
                  <p>There are no workout plans</p>
                </>
              ) : (
                <>
                  <ListGroup>
                    {plans.map((plan) => {
                      return (
                        <ListGroupItem
                          key={plan.id}
                          id={plan.id}
                          action
                          onClick={handlePlanClick}
                        >
                          {plan.title}
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
