import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import classes from "../css/login.module.css";
import { useFlash } from "../contexts/FlashProvider";
import { useUser } from "../contexts/UserProvider";
import showPasswordLogo from "../icons/password-show-dark.svg";
import hidePasswordLogo from "../icons/password-hide-dark.svg";
import Body from "../components/Body";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const flash = useFlash();
  const { login } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const onSubmit = async (ev) => {
    ev.preventDefault();

    const errors = {};
    if (!email) {
      errors.email = "Email is required";
    }
    if (!password) {
      errors.password = "Password is required";
    }
    setFormErrors(errors);

    const result = await login(email, password);
    if (result === "fail") {
      flash("Invalid username or password", "danger");
    } else if (result === "ok") {
      let next = "/feed";
      if (location.state && location.state.next) {
        next = location.state.next;
      }
      navigate(next);
    } else {
      flash("Invalid username or password", "danger");
    }
  };

  return (
    <Body loggedOut={true}>
      <div className={classes["login-container"]}>
        <h1 className={classes["login-title"]}>Welcome</h1>
        <form className={classes["form"]} onSubmit={onSubmit} noValidate>
          <div
            className={
              `${classes["input-group"]}` +
              " " +
              (formErrors.email ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <span className={classes["msg"]}>{formErrors.email}</span>
          </div>
          <div
            className={
              `${classes["password-input-group-outer"]}` +
              " " +
              (formErrors.password ? `${classes["error"]}` : "")
            }
          >
            <label htmlFor="password">Password</label>
            <div className={classes["password-input-group-inner"]}>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <img
                src={showPassword ? showPasswordLogo : hidePasswordLogo}
                className={classes["password-logo"]}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </div>
            <span className={classes["msg"]}>{formErrors.password}</span>
          </div>
          <button type="submit" className={classes["login-button"]}>
            Login
          </button>
        </form>
        <div className={classes["signup-container"]}>
          <span>Not have an account?</span>
          <Link to={"/register"}>Register</Link>
        </div>
        <div className={classes["forgot-password"]}>
          <Link to={"/reset-request"}>Forgot your password?</Link>
        </div>
      </div>
    </Body>
  );
}
