import { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

import classes from "../css/header.module.css";
import moonLogo from "../icons/moon-black.svg";
import sunLogo from "../icons/sun-black.svg";
import MenuLogo from "./svg-components/MenuLogo";
import { useUser } from "../contexts/UserProvider";
import "../css/theme.css";

export default function MobileHeader() {
  const { user, logout, theme, toggleTheme } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeHeaderOption, setActiveHeaderOption] = useState();
  const [userInitial, setUserInitial] = useState();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    setUserInitial(
      user && user.first_name ? user.first_name[0].toUpperCase() : ""
    );
  }, [user]);

  useEffect(() => {
    const path = location.pathname;
    console.log(path);
    if (user) {
      if (path.startsWith("/feed")) {
        setActiveHeaderOption("feed");
      } else if (path === "/plans/new") {
        setActiveHeaderOption("add-plan");
      } else if (path === "/posts/new") {
        setActiveHeaderOption("add-post");
      } else if (path === `/user/${Number(user.id)}/plans`) {
        setActiveHeaderOption("my-plans");
      } else if (path === `/user/${Number(user.id)}/posts`) {
        setActiveHeaderOption("my-posts");
      } else {
        setActiveHeaderOption(""); // Clear active option if path doesn't match
      }
    }
  }, [user, location]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {theme === "dark" ? "light mode" : "dark mode"}
    </Tooltip>
  );

  return (
    <>
      {user ? (
        <div className={classes["header"]} data-bs-theme={theme}>
          <div className={classes["middle-section"]}>
            <div
              className={classes["hamburger-menu"]}
              onClick={() => {
                setIsDropdownVisible(!isDropdownVisible);
              }}
            >
              <MenuLogo />
            </div>
            {isDropdownVisible && (
              <div className={classes["middle-section-links"]}>
                <NavLink
                  className={
                    `${classes["nav-link"]}` +
                    " " +
                    (activeHeaderOption === "feed"
                      ? `${classes["active-header-option-mobile"]}`
                      : "")
                  }
                  key="feed"
                  to="/feed"
                  data-value="feed"
                  onClick={() => {
                    setIsDropdownVisible(false);
                  }}
                >
                  Feed
                </NavLink>
                <NavLink
                  className={
                    `${classes["nav-link"]}` +
                    " " +
                    (activeHeaderOption === "my-plans"
                      ? `${classes["active-header-option-mobile"]}`
                      : "")
                  }
                  key="plans"
                  to={`/user/${user.id}/plans`}
                  data-value={`/user/${user.id}/plans`}
                  onClick={() => {
                    setIsDropdownVisible(false);
                  }}
                >
                  My Plans
                </NavLink>
                <NavLink
                  className={
                    `${classes["nav-link"]}` +
                    " " +
                    (activeHeaderOption === "add-plan"
                      ? `${classes["active-header-option-mobile"]}`
                      : "")
                  }
                  key="plans"
                  to={"/plans/new"}
                  data-value={`/user/${user.id}/plans`}
                  onClick={() => {
                    setIsDropdownVisible(false);
                  }}
                >
                  Add Plan
                </NavLink>

                <NavLink
                  className={
                    `${classes["nav-link"]}` +
                    " " +
                    (activeHeaderOption === "my-posts"
                      ? `${classes["active-header-option-mobile"]}`
                      : "")
                  }
                  key="posts"
                  to={`/user/${user.id}/posts`}
                  data-value={`/user/${user.id}/posts`}
                  onClick={() => {
                    setIsDropdownVisible(false);
                  }}
                >
                  My Posts
                </NavLink>
                <NavLink
                  className={
                    `${classes["nav-link"]}` +
                    " " +
                    (activeHeaderOption === "add-post"
                      ? `${classes["active-header-option-mobile"]}`
                      : "")
                  }
                  key="add-post"
                  to={"/posts/new"}
                  data-value={`/user/${user.id}/posts`}
                  onClick={() => {
                    setIsDropdownVisible(false);
                  }}
                >
                  Add Post
                </NavLink>
                <NavLink
                  className={
                    `${classes["nav-link"]}` +
                    " " +
                    (activeHeaderOption === "settings"
                      ? `${classes["active-header-option-mobile"]}`
                      : "")
                  }
                  key="settings"
                  to={"/settings"}
                  data-value={`settings`}
                  onClick={() => {
                    setIsDropdownVisible(false);
                  }}
                >
                  Settings
                </NavLink>
                <button
                  className={classes["mobile-logout-button"]}
                  onClick={() => {
                    setIsDropdownVisible(false);
                    logout();
                  }}
                >
                  Logout
                </button>
              </div>
            )}
          </div>

          <div className={classes["right-section"]}>
            <div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 150, hide: 400 }}
                overlay={renderTooltip}
              >
                <button
                  onClick={() => {
                    toggleTheme();
                  }}
                  className={classes["logo-btn"]}
                >
                  <img
                    src={theme === "dark" ? sunLogo : moonLogo}
                    className={classes["logo"]}
                  />
                </button>
              </OverlayTrigger>
            </div>
            <div className={classes["dropdown"]}>
              <button className={classes["profile-button"]}>
                <span className={classes["post-profile-picture"]}>
                  {userInitial}
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes["header"]}>
          <div className={classes["left-section"]}>
            <NavLink className={classes["home-link"]} key="home" to="/">
              Home
            </NavLink>
          </div>
          <div className={classes["hidden"]}></div>
          <div className={classes["right-section"]}>
            <button className={classes["signup-button"]}>
              <NavLink
                className={classes["signup-nav-link"]}
                key="sign-up"
                to="/register"
              >
                Sign Up
              </NavLink>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
